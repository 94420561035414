/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "./footer.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle="{data.site.siteMetadata.title}" />
      <div
        style={{
          margin: `0 auto`,
          alignSelf: 'stretch',
   
          padding: `0`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer>
        <div className="footerWrapper">
        <Link to="/"><img width="100" src={require('../images/Image 105.png')} /></Link>
    <Link to="/aboutus">About Us</Link>  
    <Link to="/players/">Showcase Roster</Link>
    <Link to="/sponsors/">Sponsorship</Link>
    <Link to="/calendar/">Calendar</Link>
    <a class="button" href="https://melvinbrownii.typeform.com/to/kjTt87">Request Tryout</a>
    <div><a href="https://www.facebook.com/Bobcatsofnova/" className="social"><img width="25px" src={require('../images/facebook.svg')}/></a><a href="https://twitter.com/bobcatsnova" className="social"><img style={{paddingleft: "10px"}} width="25px" src={require('../images/twitter.svg')} /></a></div>
  </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
