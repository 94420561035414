import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './header.css'

class Header extends React.Component {
constructor(props){
  super(props)

this.state = {
  hasScrolled: false
}
}

componentDidMount(){
  window.addEventListener('scroll', this.handleScroll)
}

handleScroll = (event) => {
  const scrollTop = window.pageYOffset

  if(scrollTop > 50){
    this.setState({ hasScrolled:true })
  } else {
    this.setState({ hasScrolled:false})
  }
}

  render() {
    return (
      <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
  <div className="HeaderGroup">
    <Link to="/"><img width="150" src={require('../images/Website_Logo.png')} /></Link>
    <Link to="/aboutus">About Us</Link>  
    <Link to="/Legacy/">Legacy</Link>
    <Link to="/players/">Showcase Roster</Link>
    <Link to="/sponsors/">Sponsorship</Link>
    <Link to="/calendar/">Calendar</Link>
    <a target="_blank" class="button" href="https://melvinbrownii.typeform.com/to/kjTt87">Request Tryout</a>
  </div>
</div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



export default Header
